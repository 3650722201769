import {
  HttpErrorResponse,
  HttpEvent,
  HttpInterceptorFn,
  HttpStatusCode,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, from, mergeMap, Observable, throwError } from 'rxjs';

import { AuthService } from '../../pages/auth/services/auth.service';

/**
 *
 * Auth interceptor
 * @param {*} req
 * @param {*} next
 * @return {*}  {Observable<HttpEvent<unknown>>}
 */
export const authInterceptor: HttpInterceptorFn = (
  req,
  next,
): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);

  const authReq = req.clone({
    setHeaders: {
      Authorization: `Bearer ${authService.getAccessToken()}`,
    },
  });
  return next(authReq).pipe(
    catchError((err: HttpErrorResponse) => {
      if (
        err?.status === HttpStatusCode.Unauthorized &&
        err.statusText === 'Unauthorized' &&
        err?.error.message !== 'Invalid Credential'
      ) {
        // Check if refresh token is expired
        if (authService.isRefreshTokenExpired()) {
          // Refresh token is expired, logout the user and redirect to login page
          authService.logout();
          return throwError(() => new Error(err?.error?.message));
        } else {
          // Access token is expired, so refresh it using the refresh token
          return from(
            authService.getNewAccessToken(authService.getRefreshToken()),
          ).pipe(
            mergeMap((newAccessToken) => {
              // Retry the request with the new access token
              const authReq = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${newAccessToken}`,
                },
              });

              return next(authReq);
            }),
            catchError((err: HttpErrorResponse) => {
              // Refreshing the access token failed, so log out the user
              // authService.logout();
              return throwError(() => new Error(err?.error?.message));
            }),
          );
        }
      } else {
        // Return an Observable that emits the error
        return throwError(() => new Error(err?.error?.message));
      }
    }),
  );
};
