import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { UserSubscription } from '../models/user-subscription.model';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  // subscription to track modal status
  isModal = new Subject<boolean>();

  subscriptionPlanDetail: Subject<UserSubscription> =
    new Subject<UserSubscription>();

  /**
   * Method for show modal.
   *
   */
  open(subscriptionPlanData: UserSubscription): void {
    this.isModal.next(true);
    this.subscriptionPlanDetail.next(subscriptionPlanData);
  }

  /**
   * Method for close modal.
   *
   */
  close(): void {
    this.isModal.next(false);
  }
}
