import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/shared/models/api-response.model';
import { Plan } from 'src/app/shared/models/plan.model';
import { SubscriptionFeature } from 'src/app/shared/models/subscription-feature.model';

import { CancelSubscription } from '../models/cancel-subscription.model';
import { OrderSummary } from '../models/order-summary.model';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  constructor(
    private httpClient: HttpClient,
    @Inject('API_URL') private apiUrl: string,
  ) {}

  subscriptionList(): Observable<ApiResponse<Plan[]>> {
    return this.httpClient.get<ApiResponse<Plan[]>>(
      `${this.apiUrl}/subscription?search=&page=1&pageSize=10`,
    );
  }

  getSubscriptionPlan(planId: number): Observable<ApiResponse<Plan>> {
    return this.httpClient.get<ApiResponse<Plan>>(
      `${this.apiUrl}/subscription/${planId}`,
    );
  }

  createCheckoutSession(
    subscriptionPlanId: number,
  ): Observable<ApiResponse<{ checkoutSessionId: string; upgrade: boolean }>> {
    const url = `${this.apiUrl}/subscription/create-checkout-session`;

    return this.httpClient.post<
      ApiResponse<{ checkoutSessionId: string; upgrade: boolean }>
    >(url, {
      subscriptionPlanId,
    });
  }

  cancelSubscription(
    cancelSubscription: CancelSubscription,
  ): Observable<ApiResponse<CancelSubscription>> {
    return this.httpClient.post<ApiResponse<CancelSubscription>>(
      `${this.apiUrl}/subscription/deactivate-plan`,
      cancelSubscription,
    );
  }

  activateSubscription(
    stripeSubscriptionId: string,
  ): Observable<ApiResponse<CancelSubscription>> {
    return this.httpClient.post<ApiResponse<CancelSubscription>>(
      `${this.apiUrl}/subscription/activate-plan`,
      { stripeSubscriptionId },
    );
  }

  getOrderSummary(sessionId: string) {
    return this.httpClient.get<ApiResponse<OrderSummary>>(
      `${this.apiUrl}/subscription/order-summary/${sessionId}`,
    );
  }

  redirectToUpdateCard(
    customerId: string,
  ): Observable<ApiResponse<{ checkoutSessionId: string; upgrade: boolean }>> {
    return this.httpClient.get<
      ApiResponse<{ checkoutSessionId: string; upgrade: boolean }>
    >(
      `${this.apiUrl}/subscription/payment/update-card?customerId=${customerId}`,
    );
  }

  updatePaymentMethod(stripeCustomerId: string, sessionId: string) {
    return this.httpClient.post(
      `${this.apiUrl}/subscription/update-payment-details`,
      {
        stripeCustomerId,
        sessionId,
      },
    );
  }

  setDefaultPaymentMethod(stripeCustomerId: string) {
    const params = new HttpParams().set('customerId', stripeCustomerId);
    return this.httpClient.get(
      `${this.apiUrl}/subscription/payment/set-default-payment-method`,
      {
        params,
      },
    );
  }

  getSubscriptionFeatureList(
    search: string,
    page: number,
    pageSize: number,
  ): Observable<ApiResponse<SubscriptionFeature[]>> {
    const params = new HttpParams()
      .set('search', search)
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.httpClient.get<ApiResponse<SubscriptionFeature[]>>(
      `${this.apiUrl}/subscription-feature`,
      { params },
    );
  }

  retryPayment(
    stripeCustomerId: string,
    subscriptionId: string,
  ): Observable<ApiResponse<{ status: string }>> {
    return this.httpClient.post<ApiResponse<{ status: string }>>(
      `${this.apiUrl}/subscription/payment/retry-payment`,
      {
        stripeCustomerId,
        subscriptionId,
      },
    );
  }
}
