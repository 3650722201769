import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { map, Observable, of, switchMap, tap } from 'rxjs';
import { ApiResponse } from 'src/app/shared/models/api-response.model';
import { DeviceInfo } from 'src/app/shared/models/deviceInfo.model';
import { Login } from 'src/app/shared/models/login.model';
import { User } from 'src/app/shared/models/users.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // access token key to get the user details from local storage
  private readonly accessTokenKey = 'access_token';

  // refresh token key to get the user details from local storage
  private readonly accessToRefreshKey = 'refresh_token';

  // log in credential key to get the login details from local storage
  private readonly credentialStorageKey = 'credential_storage';

  constructor(
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string,
    private storageService: StorageService,
    private router: Router,
    private toastService: ToastService,
  ) {}

  /**
   * Login the end user.
   * @param email - email id to login
   * @param password - password to login
   * @param rememberMe - remember the credentials
   * @returns
   */
  login(
    email: string,
    password: string,
    rememberMe: boolean,
    deviceInfo: DeviceInfo,
  ): Observable<ApiResponse<Login>> {
    return this.http
      .post<ApiResponse<Login>>(`${this.apiUrl}/auth/signIn`, {
        email,
        password,
        deviceInfo,
      })
      .pipe(
        tap((response) => {
          const accessToken = response.data?.accessToken;
          const refreshToken = response.data?.refreshToken;
          const decodedToken = jwtDecode<User>(accessToken);
          if (decodedToken.Role.name === 'user') {
            this.setAccessToken(accessToken);
            this.setRefreshToken(refreshToken);
            if (rememberMe) {
              this.saveCredentials(email, rememberMe);
            } else {
              this.storageService.removeItem(this.credentialStorageKey);
            }
          } else {
            throw new Error(
              'Access Denied: You do not have permission to login',
            );
          }
        }),
      );
  }

  /**
   * Check if user is already loggedIn somewhere.
   * @param email - email id to login
   * @returns
   */
  checkUserAlreadyLoggedIn(email: string): Observable<ApiResponse<User>> {
    return this.http.post<ApiResponse<User>>(
      `${this.apiUrl}/auth/check-loggedIn`,
      {
        email,
      },
    );
  }

  register(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    termsAndPolicy: boolean,
    deviceInfo: DeviceInfo,
  ): Observable<ApiResponse<Login>> {
    return this.http
      .post<ApiResponse<Login>>(`${this.apiUrl}/auth/signUp`, {
        firstName,
        lastName,
        email,
        password,
        termsAndPolicy,
        deviceInfo,
      })
      .pipe(
        tap((response) => {
          const accessToken = response.data?.accessToken;
          const refreshToken = response.data?.refreshToken;
          this.setAccessToken(accessToken);
          this.setRefreshToken(refreshToken);
        }),
      );
  }

  /**
   * Get new access token based on the refresh token.
   * @param refreshToken - refresh token
   * @returns
   */
  getNewAccessToken(refreshToken: string): Observable<string> {
    return this.http
      .get<
        ApiResponse<Login>
      >(`${this.apiUrl}/auth/refreshToken/${refreshToken}`)
      .pipe(
        map((response) => {
          const newAccessToken = response.data?.accessToken;
          if (newAccessToken) {
            this.setAccessToken(newAccessToken);
          } else {
            this.logout();
          }
          return newAccessToken;
        }),
      );
  }

  /**
   * Logout the end user.
   */
  logout(): void {
    this.storageService.removeItem(this.accessTokenKey);
    this.storageService.removeItem(this.accessToRefreshKey);
    this.router.navigate(['/auth']);
  }

  /**
   * Send mail to reset password.
   *
   * @param {string} email - email on which the link will be sent to reset the password.
   * @return {*}  {Observable<void>}
   * @memberof AuthService
   */
  sendMailToResetPassword(email: string): Observable<void> {
    return this.http.get<void>(
      `${this.apiUrl}/auth/forgotPassword/${email}/front`,
    );
  }

  /**
   * To reset the password.
   * @param email - email for which the password need to be reset.
   * @param token - token for verification.
   * @returns
   */
  resetPassword(password: string, token: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/auth/reSetPassword`, {
      password,
      token,
    });
  }

  /**
   * To Change the Password
   *
   * @param {number} userId
   * @param {string} oldPassword
   * @param {string} password
   * @return {*}  {Observable<User>}
   * @memberof AuthService
   */
  changePassword(
    userId: number,
    oldPassword: string,
    password: string,
  ): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}/auth/changePassword`, {
      userId,
      oldPassword,
      password,
    });
  }

  /**
   * set access token
   * @param token - the access token to set in local storage
   */
  private setAccessToken(token: string): void {
    this.storageService.setItem(this.accessTokenKey, token);
  }

  /**
   * set access token
   * @param token - the access token to set in local storage
   */
  private setRefreshToken(token: string): void {
    this.storageService.setItem(this.accessToRefreshKey, token);
  }

  /**
   * To get the access token.
   * @returns
   */
  getAccessToken(): string {
    return this.storageService.getItem(this.accessTokenKey) ?? '';
  }

  /**
   * To get the refresh token.
   * @returns
   */
  getRefreshToken(): string {
    return this.storageService.getItem(this.accessToRefreshKey) ?? '';
  }

  /**
   * Check whether refresh token is valid or expired.
   * @returns boolean
   */
  isRefreshTokenExpired(): boolean {
    if (this.getRefreshToken()) {
      const decodedToken = jwtDecode<User>(this.getRefreshToken());
      if (!decodedToken) {
        return false;
      }
      return Number(decodedToken?.exp) < Math.floor(Date.now() / 1000);
    }
    return true;
  }

  /**
   * Check whether end user is logged in or not.
   * @returns
   */
  isLoggedIn(): boolean {
    return !!this.getAccessToken();
  }

  /**
   * Fetch User details
   * @returns
   */
  getUser(): User | null {
    const token = this.storageService.getItem('access_token') ?? '';
    const decodedToken = token ? jwtDecode<User>(token) : null;
    return decodedToken;
  }

  /**
   * Save login credential to auto populate login
   * @param email - user email.
   * @param isRememberMeChecked - whether remember me is checked/unchecked.
   */
  saveCredentials(email: string, isRememberMeChecked: boolean): void {
    const credentials = { email, isRememberMeChecked };
    this.storageService.setItem(this.credentialStorageKey, credentials);
  }

  /**
   * To get the login details
   * @returns
   */
  getCredentials(): { email: string; isRememberMeChecked: boolean } | null {
    const storedCredentials = this.storageService.getItem(
      this.credentialStorageKey,
    );
    return storedCredentials;
  }

  /**
   * Logout the end user.
   */
  logoutUser(): void {
    this.getUserInformation()
      .pipe(
        switchMap((deviceInfo: DeviceInfo) => {
          const refreshToken = this.storageService.getItem(
            this.accessToRefreshKey,
          );
          return this.http
            .post<ApiResponse<Login>>(`${this.apiUrl}/auth/signOut`, {
              refreshToken,
              deviceInfo,
            })
            .pipe(
              tap(() => {
                this.storageService.removeItem(this.accessTokenKey);
                this.storageService.removeItem(this.accessToRefreshKey);
                this.router.navigate(['/auth']);
              }),
            );
        }),
      )
      .subscribe({
        error: (error) => {
          this.toastService.showToast({
            color: 'danger',
            description: error.message,
            title: 'Error',
          });
        },
      });
  }

  /**
   * Get device information
   * @return {*}  {Observable<DeviceInfo>}
   * @memberof AuthService
   */
  getUserInformation(): Observable<DeviceInfo> {
    const deviceInfo = this.storageService.getItem('deviceInfo');
    if (deviceInfo) {
      return of(deviceInfo);
    }
    return this.http.get<DeviceInfo>(`https://ipapi.co/json/`).pipe(
      tap((res) => {
        this.storageService.setItem('deviceInfo', res);
      }),
    );
  }

  /**
   * Check user is valid or not
   *
   * @param id - user id.
   * @param token - user token.
   * @return {*}  {Observable<{ isValid: boolean }>}
   * @memberof AuthService
   */
  checkUserIsValid(
    id: number,
    token: string,
  ): Observable<ApiResponse<{ isValid: boolean }>> {
    return this.http.get<ApiResponse<{ isValid: boolean }>>(
      `${this.apiUrl}/auth/valid-user/${id}/${token}`,
    );
  }
}
