<div class="custom-dropdown">
  <div class="input-wrapper" (click)="toggleOption()">
    @if (label) {
      <label
        [for]="label"
        class="font-components-button-m white"
        [class.required]="required"
      >
        {{ label }}
      </label>
    }
    <input
      type="text"
      [placeholder]="placeholder"
      class="input cursor-pointer"
      [ngModel]="selectedOption && selectedOption.text" (ngModelChange)="selectedOption && (selectedOption.text = $event)"
      readonly
    />
    <div class="down-arrow" [ngStyle]="{ top: label ? '70%' : '55%' }">
      <app-icon name="dropdown-arrow" [height]="20"></app-icon>
    </div>
  </div>
  @if (isToggleOption) {
    <ul class="header-dropdown-content">
      @for (option of optionList; track $index) {
        <li (click)="selectOption(option)">
          <a>{{ option.text }}</a>
        </li>
      }
    </ul>
  }
</div>
