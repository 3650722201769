import './shared/abstract-control.extension';

import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from './pages/auth/services/auth.service';
import { AlertComponent } from './shared/components/alert/alert.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { ToasterComponent } from './shared/components/toaster/toaster.component';
import { SocketService } from './shared/services/socket.service';
import { StorageService } from './shared/services/storage.service';
import { toastBody, ToastService } from './shared/services/toast.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    LoaderComponent,
    ToasterComponent,
    AlertComponent,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'griot-frontend';
  toastShow = false;
  toastDetail!: toastBody;

  private socketMovieSubscription!: Subscription;

  constructor(
    private toastService: ToastService,
    private router: Router,
    private socketService: SocketService,
    private authService: AuthService,
    private storageService: StorageService,
  ) {
    this.toastService.toast().subscribe((res: toastBody) => {
      this.toastDetail = res;
      this.toastShow = true;
      setTimeout(() => {
        this.toastShow = false;
      }, res.timeout);
    });

    this.router.events.subscribe(() => {
      window.scrollTo(0, 0);
    });
  }

  ngOnInit(): void {
    const user = this.authService.getUser();
    if (user) {
      const token = this.storageService.getItem('access_token');
      this.authService.checkUserIsValid(user?.id, token).subscribe({
        next: (res) => {
          if (res.data?.isValid === false) {
            this.authService.logout();
          }
        },
      });
    }
    this.socketMovieSubscription = this.socketService
      .on<{
        logout: boolean;
        userId: number;
      }>('logout')
      .subscribe({
        next: (res: { logout: boolean; userId: number }) => {
          const user = this.authService.getUser();
          if (user && res.userId === user.id) {
            this.authService.logoutUser();
          }
        },
      });
  }

  ngOnDestroy(): void {
    if (this.socketMovieSubscription) {
      this.socketMovieSubscription.unsubscribe();
    }
  }
}
