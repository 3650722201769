<router-outlet></router-outlet>
<app-loader></app-loader>
<app-alert></app-alert>
@if (toastShow) {
  <app-toaster
    [color]="toastDetail.color"
    [title]="toastDetail.title"
    [description]="toastDetail.description"
  ></app-toaster>
}
