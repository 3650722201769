import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { DropDownData } from '../../models/drop-down.model';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-drop-down',
  standalone: true,
  imports: [CommonModule, FormsModule, IconComponent],
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropDownComponent),
      multi: true,
    },
  ],
})
export class DropDownComponent implements ControlValueAccessor {
  isToggleOption = false;
  @Input() selectedOption!: DropDownData;
  @Input() optionList!: DropDownData[];
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Output() changeDropDown: EventEmitter<DropDownData> = new EventEmitter();

  @HostListener('document:click', ['$event'])
  clickOut(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isToggleOption = false;
    }
  }

  constructor(private elementRef: ElementRef) {}

  toggleOption() {
    this.isToggleOption = !this.isToggleOption;
    this.onTouched();
  }

  selectOption(dropDownData: DropDownData) {
    this.selectedOption = dropDownData;
    this.toggleOption();
    this.changeDropDown.emit(dropDownData);
    this.onChange(dropDownData);
    this.onTouched();
  }

  /**
   * Override method of ControlValueAccessor interface
   * @returns {DropDownData} {void}
   * @memberof DropDownComponent
   */
  writeValue(value: DropDownData): void {
    if (value !== undefined) {
      this.selectedOption = value;
    }
  }

  /**
   * Override method of ControlValueAccessor interface, that change registered value
   *
   * @param {(value: DropDownData) => void} fn
   * @memberof DropDownComponent
   */
  registerOnChange(fn: (value: DropDownData) => void): void {
    this.onChange = fn;
  }

  /**
   * Override method of ControlValueAccessor interface, that run when touch control
   *
   * @param {() => void} fn
   * @memberof DropDownComponent
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private onChange: (value: DropDownData) => void = () => {};
  private onTouched: () => void = () => {};
}
