<div
  [@fadeInOut]
  class="toaster-container flex-align-center gap-16"
  [ngClass]="color"
>
  <div>
    <div class="icon-bg" [ngClass]="'icon-bg-' + color">
      <app-icon [name]="color"></app-icon>
    </div>
  </div>
  <div class="toaster-detail">
    @if (title) {
      <span class="font-base-copy-bold-m white">{{ title }}</span>
    }
    @if (description) {
      <span class="grayscale font-base-copy-body-s">
        {{ description }}
      </span>
    }
  </div>
</div>
