@if (isModal | async) {
  <form class="form-wrapper" [formGroup]="cancelSubscriptionForm">
    <div class="alert">
      <div class="deactivate-plan-modal">
        <div class="space-between-center">
          <span class="font-base-header-700-md white">Deactivate Plan</span>
          <app-icon
            name="xmark-white"
            (click)="close()"
            [height]="32"
            [width]="32"
            class="cursor-pointer"
          ></app-icon>
        </div>
        <p class="font-base-copy-body-500-m white">
          Please select reason to why you want deactivate plan
        </p>
        <div class="input-wrapper width-100 mt-30">
          <app-drop-down
            placeholder="Please Select Reason"
            label="Select Reason"
            [optionList]="reasons"
            [required]="true"
            formControlName="cancellationReason"
          ></app-drop-down>
          @if (
            this.cancelSubscriptionForm.get("cancellationReason")?.showError()
          ) {
            <div class="danger font-base-copy-body-s width-100 error-wrapper">
              <app-icon
                name="exclamation"
                [width]="12"
                [height]="12"
              ></app-icon>
              @if (
                this.cancelSubscriptionForm
                  .get("cancellationReason")
                  ?.hasError("required")
              ) {
                <span>Cancellation reason is required</span>
              }
            </div>
          }
        </div>
        <div class="mt-16">
          <button class="button-primary width-100" (click)="deactivatePlan()">
            Deactivate Plan
          </button>
        </div>
      </div>
    </div>
  </form>
}
