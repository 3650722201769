import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  // subscription to track loader status
  isLoading = new Subject<boolean>();
  public isLoadingSubject: Observable<boolean> = this.isLoading.asObservable();

  /**
   * Method for show loader.
   *
   */
  show(): void {
    this.isLoading.next(true);
  }

  /**
   * Method for hide loader.
   *
   */
  hide(): void {
    this.isLoading.next(false);
  }
}
