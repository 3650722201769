import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export type toastBody = {
  title: string;
  description: string;
  color: 'success' | 'danger';
  timeout?: number;
};

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastObservable$: Subject<toastBody> = new Subject();

  constructor() {}

  toast(): Observable<toastBody> {
    return this.toastObservable$.asObservable();
  }

  showToast(data: toastBody): void {
    data.timeout || (data.timeout = 2000);
    this.toastObservable$.next(data);
  }
}
