import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { CancelSubscription } from 'src/app/pages/payment/models/cancel-subscription.model';
import { PlanService } from 'src/app/pages/payment/services/plan.service';
import { UserService } from 'src/app/pages/settings/services/user.service';

import { DropDownData } from '../../models/drop-down.model';
import { UserSubscription } from '../../models/user-subscription.model';
import { AlertService } from '../../services/alert.service';
import { ToastService } from '../../services/toast.service';
import { DropDownComponent } from '../drop-down/drop-down.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [
    CommonModule,
    DropDownComponent,
    ReactiveFormsModule,
    IconComponent,
  ],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AlertComponent implements OnInit {
  cancelSubscriptionForm!: FormGroup;
  reasons: DropDownData[] = [
    {
      text: 'Too many notifications',
      value: 'Too many notifications',
    },
    {
      text: 'Usability and user experience issues',
      value: 'Usability and user experience issues',
    },
    {
      text: 'Not enough content for engagement',
      value: 'Not enough content for engagement',
    },
    {
      text: 'Poor video quality',
      value: 'Poor video quality',
    },
    {
      text: 'Others',
      value: 'Others',
    },
  ];

  // subscriber for checking flag for open/close loader
  isModal: Subject<boolean> = this.alertService.isModal;

  subscriptionPlanDetail!: UserSubscription;

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private planService: PlanService,
    private toast: ToastService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.initCancelSubscriptionForm();
    this.alertService.subscriptionPlanDetail.subscribe((subscriptionPlan) => {
      this.subscriptionPlanDetail = subscriptionPlan;
    });
  }

  initCancelSubscriptionForm() {
    this.cancelSubscriptionForm = this.formBuilder.group({
      cancellationReason: ['', Validators.required],
    });
  }

  /**
   * Method for close modal.
   *
   * @memberof AlertComponent
   */
  close() {
    this.alertService.close();
  }

  /**
   * Deactivate Plan
   *
   * @memberof AlertComponent
   */
  deactivatePlan() {
    if (this.cancelSubscriptionForm.invalid) {
      this.cancelSubscriptionForm.markAllAsTouched();
      return;
    }
    const data = this.cancelSubscriptionForm.value;
    const cancelSubscription: CancelSubscription = {
      cancellationReason: data.cancellationReason.value,
      stripeSubscriptionId: this.subscriptionPlanDetail.stripeSubscriptionId,
    };

    if (this.subscriptionPlanDetail.upcomingSubscriptionId) {
      cancelSubscription.upcomingSubscriptionId =
        this.subscriptionPlanDetail.upcomingSubscriptionId;
    }

    this.planService.cancelSubscription(cancelSubscription).subscribe({
      next: () => {
        this.userService.planSubscription$.next(true);
        this.toast.showToast({
          color: 'success',
          description: 'Plan deactivated successfully',
          title: '',
        });
        this.alertService.close();
      },
    });
  }
}
