import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss',
})
export class IconComponent {
  // Input name
  @Input() name: string = '';

  //Input width
  @Input() width = 24;

  //Input height
  @Input() height = 24;

  @Input() class: string = '';
}
