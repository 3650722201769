import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiResponse } from 'src/app/shared/models/api-response.model';
import { User } from 'src/app/shared/models/users.model';

import { AuthService } from '../../auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: Subject<User> = new Subject();
  user$: Observable<User> = this.user.asObservable();
  planSubscription$: Subject<boolean> = new Subject();
  userSubscriptionDetailForActivate$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    @Inject('API_URL') private apiUrl: string,
  ) {}

  getSubScriptionStatus() {
    return this.planSubscription$.asObservable();
  }

  getPlanUpdatedSubScriptionStatus() {
    return this.userSubscriptionDetailForActivate$.asObservable();
  }

  /**
   * Get user info by user id
   * @param {number} userId
   * @return {*}  {Observable<ApiResponse<User>>}
   * @memberof UserService
   */
  getUser(userId: number): Observable<ApiResponse<User>> {
    return this.http.get<ApiResponse<User>>(`${this.apiUrl}/user/${userId}`);
  }

  getUserData() {
    const user = this.authService.getUser();
    if (user) {
      this.getUser(user.id).subscribe({
        next: (res) => {
          this.user.next(res.data);
        },
      });
    }
  }

  /**
   * Update user by Id
   * @param {number} id
   * @param {*} data
   * @return {*}  {Observable<ApiResponse<User>>}
   */
  updateById(id: number, data: FormData): Observable<ApiResponse<User>> {
    return this.http.patch<ApiResponse<User>>(
      `${this.apiUrl}/user/${id}`,
      data,
    );
  }

  /**
   * Deactivate user by Id
   * @param {number} id
   * @return {*}  {Observable<ApiResponse<User>>}
   */
  deactivateUser(id: number, reason: string): Observable<ApiResponse<User>> {
    return this.http.patch<ApiResponse<User>>(
      `${this.apiUrl}/user/deactivate/${id}`,
      { reason },
    );
  }
}
